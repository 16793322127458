//
// Spinners
//



$kt-spinner-config: (
	size: (
		sm: 16px,
		md: 20px,
		lg: 24px
	)
);
