//
// Demo Config
//

// Layout
$kt-aside-default-width: 255px;
$kt-aside-minimize-width: 70px;
$kt-aside-offcanvas-width: 255px;

$kt-page-padding: (
	desktop: 15px,
	mobile: 10px
);

// Brand Color
$kt-state-colors: (
    // Metronic states
    brand: (
        base: #5d78ff,
        inverse: #ffffff
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff
    ),

    // Bootstrap states
    primary: (
        base: #5867dd,
        inverse: #ffffff
    ),
    success: (
        base: #0abb87, //1dc9b7,
        inverse: #ffffff
    ),
    info: (
        base: #5578eb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff
    )
);

// Base colors
$kt-base-colors: (
    label: (
        1: #c5cbe3,
        2: #a1a8c3,
        3: #646c9a,
        4: #3d4465
    ),
    shape: (
        1: #f0f3ff,
        2: #e8ecfa,
        3: #93a2dd,
        4: #646c9a
    ),
    grey: (
        1: #f7f8fa, //#f4f5f8
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%)
    )
) !default;

// Layout Skins
$kt-layout-skins: (
	dark: #1e1e2d,
	light: #ffffff
);

// content bg color
$kt-content-bg-color: #f2f3f8;

// Border Radius
$kt-border-radius: 4px;

// Portlet settings
$kt-portlet: (
    min-height: (
        default: (
            desktop: 60px,
            mobile: 50px
        ),
        lg: (
            desktop: 80px,
            mobile: 60px
        ),
        xl: (
            desktop: 100px,
            mobile: 80px
        )
    ),
    space: (
        desktop: 15px,
        mobile: 10px
    ),
    bottom-space: (
        desktop: 15px,
        mobile: 10px
    ),
    border-color: #ebedf2,
    bg-color: #ffffff,
    shadow: 0px 0px 30px 0px rgba(82,63,105,0.05)
) !default;

// Root Font Settings
$kt-font-families: (
    regular: 'Poppins',
    heading: 'Poppins'
) !default;

// Root Font Settings
$kt-font-size: (
    size: (
        desktop: 12px,
        tablet: 11px,
        mobile: 11px
    ),
    weight: 300
) !default;

// Root Font Colors
$kt-font-color: (
    text: #646c9a,
    link: (
        default: #5d78ff,
        hover: darken(#5d78ff, 6%)
    )
) !default;