//
// Default Stylesheets
//




////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
// 1: Demo Config
@import "config";  // demo level framework config override

// 2: Framework Config
@import "../../../framework/config"; // framework config

// 3: Bootstrap Include
@import "../../../framework/core/bootstrap/bootstrap.scss";

// 4: Framework Include
@import "../../../framework/include"; 
////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 5: Demo Configs
@import "header/config";

@import "brand/config";

@import "aside/config";

@import "footer/config";


// 6: Demo Includes
// Layout
@import "base/base";

@import "header/base";
@import "header/base-mobile";
@import "header/menu";
@import "header/topbar";

@import "brand/base";

@import "aside/base";

@import "content/base";

@import "footer/base";