//
// Subheader
//




.kt-subheader {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
	padding: kt-get($kt-subheader-config, style, solid, space, desktop) kt-get($kt-page-padding, desktop);
	background-color: #fff;

	// Head actions buttons
	.btn {
		display: inline-flex;
		margin-left: 0.5rem;
		height: 32px !important;
		padding-top: 0;
		padding-bottom: 0;

		i {
			font-size: 1rem !important;
		}

		&.btn-icon {
			width: 32px !important;
		}
	}

	// Main
	.kt-subheader__main {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;

		// Buttons
		.btn { 
			margin-left: 0;
			margin-right: 0.5rem;
		}

		// Title
		.kt-subheader__title {
			margin: 0;
			padding: 0 1rem 0 0;
			font-size: 1.2rem;
			font-weight: 500;
			color: #434349;

			small {
				padding-left: 0.25rem;
				font-size: 0.9rem;
				color: kt-base-color(label, 2);
			}
		}

		// Desc
		.kt-subheader__desc {
			padding: 0 0.5rem 0 0;
			font-size: 1rem;
			font-weight: 500;
			color: #959cb6;
		}

		// Separator
		.kt-subheader__separator {
			display: block;
			width: 12px;
			height: 4px;
			border-radius: 2px;
			background: #e7e8ef;
			margin: 0 1rem 0 0;

			&.kt-subheader__separator--v {
				width: 1px;
				height: 22px;
			}
		}

		// Breadcrumbs
		.kt-subheader__breadcrumbs {
			display: flex;
			align-items: center;
			list-style-type: none;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;

			.kt-subheader__breadcrumbs-home {
				padding: 0px 0.65rem 0px 0px;
				@include kt-transition();

				> i {
					font-size: 1rem;
					color: #c5cbe7;
				}

				&:hover {
					> i {
						@include kt-transition();
						color: kt-brand-color();
					}
				}

				&.kt-subheader__breadcrumbs-home--active {
					> i {
						color: kt-brand-color();
					}
				}
			}

			.kt-subheader__breadcrumbs-link {
				padding: 0 0.35rem 0 0;
				font-size: 1rem;
				font-weight: 500;
				color: #959cb6;

				&:hover {
					color: kt-brand-color();
				}

				&.kt-subheader__breadcrumbs-link--active {
					color: kt-brand-color();
				}

				@include kt-hover-transition();
			}

			.kt-subheader__breadcrumbs-separator {
				display: flex;
				justify-content: content;
				align-items: center;
				padding: 0 0.35rem 0 0;

				&:after {
					display: block;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					content: " ";
					background: #e7e8ef;
				}
			}
		}
	}

	// Toolbar
	.kt-subheader__toolbar {
		display: flex;
		justify-content: flex-end;	
		align-items: center;
		padding: 0;

		// Toolbar buttons
		.btn {
			margin-left: 0.25rem;
		}
	}

	// Primary button
	.kt-subheader__btn-primary {
		background: #f2f3f7;
		color: #f2f3f7;
		font-weight: 500;

		i {
			color: #959cb6;
		}

		&:hover,
		&.active,
		&:active,
		&:focus {
			background: kt-brand-color();

			i {
				color: kt-brand-color(inverse);
			}
		}
	}

	// Secondary button
	.kt-subheader__btn-secondary {
		color: #959cb6;
		font-weight: 500;

		i {
			color: #aab1df;
		}

		&:hover,
		&.active,
		&:active,
		&:focus {
			background: #f2f3f7;
			color: kt-brand-color();
		}
	}

	// Daterange
	.kt-subheader__btn-daterange {
		background: #f2f3f7;

		.kt-subheader__btn-daterange-title {
			display: inline-block;
			color: #959cb6;
			font-weight: 500;
		}

		.kt-subheader__btn-daterange-date {
			display: inline-block;
			color: kt-brand-color();
			margin-right: 0.75rem;
			font-weight: 500;
		}

		i {
			color: kt-brand-color();
		}

		&:hover,
		&.active,
		&:active,
		&:focus {
			background: #f2f3f7;

			i {
				color: kt-brand-color();
			}
		}
	}

	// Search
	.kt-subheader__search {
		width: 175px;
		display: inline-block;
		
		.form-control {
			font-size: 0.9rem;
			height: 33px;
			background: #f2f3f7;
			border-color: #f2f3f7 !important;
		}
		
		.kt-input-icon__icon {
			i {
				font-size: 1.2rem;
				color: kt-brand-color();
			}
		}
	}

	// Transparent style
	.kt-subheader--transparent & {
		background-color: transparent;
		padding: kt-get($kt-subheader-config, style, light, space, desktop) kt-get($kt-page-padding, desktop);

		// Main
		.kt-subheader__main {
			.kt-subheader__separator {
				background: darken(#e7e8ef, 3%);
			}

			.kt-subheader__breadcrumbs {
				.kt-subheader__breadcrumbs-home {
					> i {
						color: darken(#c5cbe7, 3%);
					}
				}

				.kt-subheader__breadcrumbs-link {
					color: #959cb6;
				}

				.kt-subheader__breadcrumbs-separator {
					&:after {
						background: darken(#e7e8ef, 3%);
					}
				}
			}
		}

		// Primary button
		.kt-subheader__btn-primary {
			background: #eaecf2;
			color: #f2f3f7;

			i {
				color: #959cb6;
			}

			&:hover,
			&.active,
			&:active,
			&:focus {
				background: kt-brand-color();

				i {
					color: kt-brand-color(inverse);
				}
			}
		}

		// Secondary button
		.kt-subheader__btn-secondary {
			color: darken(#959cb6, 3%);

			i {
				color: darken(#aab1df, 3%);
			}

			&:hover,
			&.active,
			&:active,
			&:focus {
				background: #eaecf2;
			}
		}

		// Daterange
		.kt-subheader__btn-daterange {
			background: #eaecf2;

			&:hover,
			&.active,
			&:active,
			&:focus {
				background: #eaecf2;
			}
		}

		// Search
		.kt-subheader__search {
			.form-control {
				background: #eaecf2;
				border-color: #eaecf2 !important;
			}
		}
	}
}

@include kt-tablet-and-mobile {
	// Subheader
	.kt-subheader {
		padding: kt-get($kt-subheader-config, style, solid, space, mobile) kt-get($kt-page-padding, mobile);

		// Main
		.kt-subheader__main {
			.kt-subheader__title {
				padding: 0.5rem 1.25rem 0.5rem 0;
			}

			.kt-subheader__breadcrumbs {
				padding: 0.5rem 0;
			}
		}

		// Toolbar
		.kt-subheader__toolbar {
			padding: 0.5rem 0;
		}

		// Light style
		.kt-subheader--transparent & {
			padding: kt-get($kt-subheader-config, style, light, space, mobile) kt-get($kt-page-padding, mobile);
		}
	}	
}

@include kt-mobile {
	.kt-subheader {
		.kt-subheader__toolbar {
			padding-top: 0.5rem;
		}
	}
}