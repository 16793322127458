//
// Quick Panel
//



$kt-quick-panel: (
	self: (
		padding: 0,
		bg-color: #fff,
		shadow: 0px 0px 28px 0px rgba(82,63,105,0.13),

		offcanvas: (
			layout: (
				self: (
					zindex: 1001,
					direction: right,
					transition:	all .3s ease,
					width: 425px
				),

				close: (
					size: 25px,
					font-size: 1.4rem
				)
			),

			skin: (
				self: (
					bg-color: #ffffff,
					shadow: 0px 1px 9px -3px rgba(0,0,0,0.75)
				),

				overlay: (
					bg-color: rgba(#000000, 0.1),
				),

				close: (
					icon-color: (
						default: #968ad4,
						hover: #ffffff
					),
					bg-color: (
						default: darken(#f8f8fb, 5%),
						hover: kt-brand-color()
					)
				)
			)
		)
	)
)