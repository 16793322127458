//
// Config
//




$kt-checkbox-base-color: kt-base-color(grey, 1);

$kt-checkbox-config: (
	self: (
		transition: all 0.3s ease,
		size: 18px,
		tick: 6px
	),
	
	outline: (
		border: (
			default: darken($kt-checkbox-base-color, 12%),
			checked: darken($kt-checkbox-base-color, 15%)
		),
		tick: darken($kt-checkbox-base-color, 18%)
	),

	solid: (
		bg: (
			default: darken($kt-checkbox-base-color, 6%),
			checked: darken($kt-checkbox-base-color, 8%)
		),
		tick: darken($kt-checkbox-base-color, 30%)
	)
);