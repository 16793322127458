//
// Section
//



.kt-section {
    padding: 0;
    margin: 0 0 2rem 0;

    &.kt-section--space-sm {
        margin-bottom: 1rem;
    }

    &.kt-section--space-md {
        margin-bottom: 2rem;
    }

    &.kt-section--space-lg {
        margin-bottom: 2.5rem;
    }

    &.kt-section--space-xl {
        margin-bottom: 3rem;
    }

    .kt-section__heading,
    .kt-section__title {
        display: block;
        font-size: 1.3rem;
        font-weight: 500;
        padding: 0;
        margin: 0 0 1.75rem 0;
        color: kt-base-color(label, 4);

        &.kt-section__title-sm {
            font-size: 1.1rem;
        }

        &.kt-section__title-md {
            font-size: 1.4rem;
        }

        &.kt-section__title-lg {
            font-size: 1.5rem;
        }
    }

    .kt-section__desc {
        display: block;
        font-size: 1rem;
        font-weight: 400;
        padding: 0;
        margin: 0 0 1rem 0;
        color: kt-base-color(label, 3);
    }

    .kt-section__info {
        display: block;
        font-size: 1rem;
        padding: 0;
        margin: 0 0 1rem 0;
        color: kt-base-color(label, 3);
    }

    .kt-section__content {
        display: block;
        padding: 0;
        margin: 0;

        &.kt-section__content--border {
            padding: 1.5rem;
            border: 3px solid kt-base-color(grey, 2);
        }
        
        &.kt-section__content--solid {
            padding: 1.5rem;
            border-left: 4px solid kt-base-color(grey, 3);
            background-color: kt-base-color(grey, 1);

            @include kt-rounded {
                border-radius-top-left: $kt-border-radius;
                border-radius-top-right: $kt-border-radius;
            }
        }

        &.kt-section__content--x-fit {
            padding-left: 0;
            padding-right: 0;
        }

        &.kt-section__content--fit {
            padding: 0;
        }
    }

    &.kt-section--last {
        margin-bottom: 0;
    }
}